import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PoliticaCookies = () => (
    <Layout>
        <SEO title="Política de cookies" />

        <div className="page-legal container">
            <h1>Política de cookies</h1>

            <p>El acceso a esta página web puede implicar la utilización de “cookies”.</p>
            <p>Las cookies son esenciales para el funcionamiento de internet, aportando innumerables ventajas en la prestación de servicios interactivos, facilitándole la navegación y usabilidad de nuestra web. Tenga en cuenta que las cookies no pueden dañar su equipo y que, a cambio, el que estén activadas nos ayudan a identificar y resolver los errores.</p>
            <p>La información que le proporcionamos a continuación, le ayudará a comprender los diferentes tipos de cookies:</p>
            <p>Cookies de sesión: son cookies temporales que permanecen en el archivo de cookies de su navegador hasta que abandone la página web, por lo que ninguna queda registrada en el disco duro del usuario. La información obtenida por medio de estas cookies, sirven para analizar pautas de tráfico en la web. A la larga, esto nos permite proporcionar una mejor experiencia para mejorar el contenido y facilitando su uso.
                Cookies permanentes: son almacenadas en el disco duro y nuestra web las lee cada vez que usted realiza una nueva visita. Una web permanente posee una fecha de expiración determinada. La cookie dejará de funcionar después de esa fecha. Las utilizamos, generalmente, para facilitar los servicios de compra y registro.
                A continuación publicamos una relación de las principales cookies utilizadas en nuestras web´s, distinguiendo:</p>
            <p>Las cookies estrictamente necesarias como por ejemplo, aquellas que sirvan para una correcta navegación o las que permitan realizar el pago de bienes o servicios solicitados por el usuario o cookies que sirvan para asegurar que el contenido de la página web se carga eficazmente.</p>
            <p>Las cookies de terceros como por ejemplo, las usadas por redes sociales, o por complementos externos de contenido como google maps.</p>
            <p>Las cookies analíticas con propósitos de mantenimiento periódico, y en aras de garantizar el mejor servicio posible al usuario, los sitios web´s hacen uso normalmente de cookies “analíticas” para recopilar datos estadísticos de la actividad .
                Relación y descripción de cookies:</p>
            <p>Las tablas que publicamos a continuación recogen de forma esquematizada las cookies anteriormente descritas y utilizadas en las distintas webs de VENTURAE CAP S.L.:</p>
            <ul>
                <li>Cookie de Google Analytics</li>
            </ul>
            <h2>Garantías complementarias – Gestión de cookies:</h2>
            <p>Como garantía complementaria a las anteriormente descritas, el registro de las cookies podrá estar sujeto a su aceptación durante la instalación o puesta al día del navegador usado, y esta aceptación puede en todo momento ser revocada mediante las opciones de configuración de contenidos y privacidad disponibles.</p>
            <p>Muchos navegadores permiten activar un modo privado mediante el cual las cookies se borran siempre después de su visita.</p>
            <p>Dependiendo de cada navegador este modo privado, puede tener diferentes nombres. A continuación encontrará una lista de los navegadores más comunes y los diferentes nombres de este “modo privado”:</p>
            <ul>
                <li>Internet Explorer 8 y superior; InPrivate</li>
                <li>Safari 2 y superior; Navegación Privada</li>
                <li>Opera 10.5 y superior; Navegación Privada</li>
                <li>FireFox 3.5 y superior; Navegación Privada</li>
                <li>Google Chrome 10 y superior; Incógnito</li>
            </ul>
            <p>Importante: Por favor, lea atentamente la sección de ayuda de su navegador para conocer más acerca de cómo activar el “modo privado”. Podrá seguir visitando nuestras web´s aunque su navegador esté en “modo privado”, si bien, su navegación por nuestra web puede no ser óptima y algunas utilidades pueden no funcionar correctamente.</p>
        </div>
    </Layout>
)

export default PoliticaCookies

